@font-face {
  font-family: Lobster;
  src: url(./assets/fonts/Lobster-Regular.ttf);
}

@font-face {
  font-family: OpenSans;
  src: url(./assets/fonts/OpenSans-Light.ttf);
}

body {
  margin: 0;
  font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rec.rec-arrow {
  display: none;
}

.rec.rec-arrow:hover {
  display: none;
}

:root {
  --theme-black: #000;
  --theme-white: #fff;
  --theme-grey: #8890a6;
  --theme-dark-grey: #707070;
  --theme-main-color: #d58296;
  --theme-light-pink: #fcf8f9;
  --theme-main-background: #eeede9;
}
